import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Text, Button, Image, Divider } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import Section from "../../components/adventures/Section"
import Block from "../../components/adventures/Block"
import PageNumber from "../../components/adventures/PageNumber"

import registrarImage from "../../images/treasure/registrar.jpg"

const AdventuresHowToPage = () => (
  <Layout>
    <SEO title="Adventures" />

    <Breadcrumbs title="How To" headline="Major keys" />

    <Grid variant="textWrap" sx={{ alignItems: "center" }}>
      <Box>
        <Text as="p" variant="body">Some Quest adventures are available as web-based manuals. On this page, we’ll show you some key ways that we present information throughout these manuals.</Text>
        <Text as="p" variant="body">As you read our adventure manuals, remember that Quest is a collaborative storytelling game. These manuals are not like a script to a movie. We’ve left lots of blanks for you and your players to fill in. If you’re feeling creative, you can use the information in a premade adventure as a template to create your own content.</Text>
        <Text as="p" variant="body">The <Text as="a" href="/store/quest-game-book" variant="link">Quest Game Book</Text> is required to play these adventures.</Text>
      </Box>

      <Image src={registrarImage} alt="Registrar" sx={{ display: "block", width: "100%", my: [0, 0, 0, -7], mx: "auto", maxWidth: [320, 400] }} />
    </Grid>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Page Number References</Text>
        <Text as="p" variant="body">This manual occasionally references information that is located in the Quest Game Book, like special abilities and magic treasure.</Text>
        <Text as="p" variant="body">When you see <PageNumber digital="XX" physical="XX">text like this</PageNumber>, you can hover over it to find out where more information is located in the Game Book. Page numbers starting with the letter "D" refer to the Digital Edition of the Game Book, and numbers starting with the letter "P" refer to the physical edition.</Text>
      </Box>
    </Grid>

    <Grid variant="base">
      <Divider />
    </Grid>
    
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Open Context</Text>
        <Text as="p" variant="body">Context helps you and the players understand parts of the scene. Some context may be obvious to the players, like a menacing beast that they should know is too difficult to fight. Other bits of context may not be obvious — like the history of a place, or the reason why something looks suspicious. When you see a context box with this style, you can use it to enlighten the players. You can divulge the information in this box when it seems appropriate to do so in the scene.</Text>
      </Box>
    </Grid>
        
    <Section type="Context">
      <Block type="Open Context" title="Journey to The Dark Spire">
        <Text>The adventurers have by now received a map from the quest giver that leads them through the wilderness toward The Dark Spire. The notes on the map reveal several dangerous spots along the journey.</Text>
      </Block>
    </Section>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Secret Context</Text>
        <Text as="p" variant="body">Some context is only for The Guide to see at first. You can reveal it to the players if they find a way to discover it.</Text>
      </Box>
    </Grid>
    
    <Section type="Context">
      <Block type="Secret Context" title="The Invisible Stalker">
        <Text>During their journey, the adventurers will be followed by an Invisible Stalker who witnessed them receive the map from the Quest Giver. At an opportune moment, when the party's guard is down, have the Invisible Stalker attempt to steal the map.</Text>
      </Block>
    </Section>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Exposition</Text>
        <Text as="p" variant="body">The world comes alive when you describe it to the players. Adventure manuals include descriptive text that you can directly read aloud to the players.</Text>
        <Text as="p" variant="body">As always, feel free to modify descriptions to fit the needs of your group and story.</Text>
      </Box>
    </Grid>
    
    <Section type="Context">
      <Block type="Exposition" title="Approach to The Spire">
        <Text>You emerge from the smoldering forest. As smoke clears from your eyes, you see a lone black spire twisting angrily toward the sky, some five stories tall. A foul gust howls through the charred corpses of trees and through the foot of the spire, lashing its broken double doors.</Text>
      </Block>
    </Section>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Dialog</Text>
        <Text as="p" variant="body">While you’re in full control of what your characters do, we’ll occasionally give you suggestions about things for them to say. When you see quoted text like this, you can read it aloud to the players at the table.</Text>
      </Box>
    </Grid>

    <Section type="Dialog" cite="Keeper of The Dark Spire">
      <Text>“Greetings, adventurers. I've been expecting you. I trust your journey has been arduous, and that you require some rest. Please enter and join me for dinner!”</Text>
    </Section>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="title02">Character Blocks</Text>
        <Text as="p" variant="body">When an NPC appears in the story, we'll provide a “stat block” like the one shown here. “HP” signifies the creature’s hit points, and “ATK” signifies how much damage it can deal when attacking. Important characters may have additional information, like a backstory, an ideal, a flaw, goals, secrets, and relationships.</Text>
        <Text as="p" variant="body">Some creatures have special features and abilities, which you can read more about in the Quest Game Book.</Text>
      </Box>
    </Grid>
      
    <Section
      type="Character"
      name="NPC Name"
      pronouns="they/theirs"
      description="Description"
      hp="20"
      atk="100"
      abilities="Test, Data" />

    <Grid variant="textWrap" sx={{ mt: [7, 8] }}>
      <Box>
        <Text as="p" variant="title02" sx={{ mb: 1 }}>Go Forth</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md", mb: 5 }}>That’s it! You’re ready to get started.</Text>
        <Link to="/adventures">
          <Button variant="secondary">Explore Adventures</Button>
        </Link>
      </Box>
    </Grid>
  </Layout>
)

export default AdventuresHowToPage